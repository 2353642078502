var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          staticStyle: { padding: "10px" },
          attrs: {
            placement: "bottom-end",
            width: _vm.$i18n.locale === "cn" ? 300 : 400,
            trigger: "click",
          },
        },
        [
          _c("div", { staticClass: "personal-center" }, [
            _c("img", {
              staticStyle: { height: "60px" },
              attrs: { src: require("@/assets/images/avatar.png"), alt: "" },
            }),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("welcome")) + "，" + _vm._s(_vm.username)),
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small", round: "" },
                    on: {
                      click: function ($event) {
                        _vm.changePasswordVisible = true
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("changePsw")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small", round: "" },
                    on: {
                      click: function ($event) {
                        _vm.changeEmailVisible = true
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("changeEmail")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger", size: "small", round: "" },
                    on: { click: _vm.logout },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("signOut")) + " ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              style: { color: _vm.color },
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                { staticClass: "personal" },
                [
                  _c("el-avatar", {
                    attrs: {
                      size: 22,
                      src: require("@/assets/images/avatar.png"),
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.username))]),
                  _c("span", [
                    _c("em", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.changePasswordVisible
        ? _c("change-password-dialog", {
            attrs: { visible: _vm.changePasswordVisible },
            on: {
              closed: function ($event) {
                _vm.changePasswordVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.changeEmailVisible
        ? _c("change-email-dialog", {
            attrs: { visible: _vm.changeEmailVisible },
            on: {
              closed: function ($event) {
                _vm.changeEmailVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }